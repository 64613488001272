export class Owner {
  constructor(
    public readonly username: string,
    public readonly type: OwnerType = 'user',
  ) {}
  toJSON() {
    return {
      username: this.username,
      type: this.type,
    }
  }
  static fromJSON(json: any) {
    return new Owner(json.username, json.type)
  }
}

export type OwnerType = 'user' | 'org'
