import { PullRequest } from '@shared/github/pr'
import { Owner } from '@shared/github/owner'
import { deserializePRs } from '@shared/serialization/deserializePRs'

export const fetchOrgData = async (orgName: string): Promise<PullRequest[]> => {
  const baseUrl =
    window.location.hostname === 'localhost' ? '/data' : 'https://demo-data.prmetrics.app'

  const response = await fetch(`${baseUrl}/${orgName.toLowerCase()}.csv`)
  const csvData = await response.text()
  const owner = new Owner(orgName, 'org')
  try {
    return deserializePRs(csvData, owner)
  } catch (error) {
    console.error('Error in deserializePRs:', error)
    throw error
  }
}
