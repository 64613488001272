import { parse } from 'csv-parse/browser/esm/sync'
import {
  CommentAuthorAssociation,
  PullRequestReviewDecision,
  StatusState,
} from '@octokit/graphql-schema'
import { PullRequest, PRStage } from '@shared/github/pr.js'
import { Repo } from '@shared/github/repo.js'
import { Owner } from '@shared/github/owner.js'
import { AuthorType } from '@shared/github/githubTypes.js'
import {
  authorAssociationMap,
  authorTypeMap,
  reviewDecisionMap,
  checksStatusMap,
} from './serializePRs.js'

// Reverse mappings for deserialization
const reverseAuthorAssociationMap = Object.fromEntries(
  Object.entries(authorAssociationMap).map(([key, value]) => [value, key]),
)

const reverseAuthorTypeMap = Object.fromEntries(
  Object.entries(authorTypeMap).map(([key, value]) => [value, key]),
)

const reverseReviewDecisionMap = Object.fromEntries(
  Object.entries(reviewDecisionMap).map(([key, value]) => [value, key]),
)

const reverseChecksStatusMap = Object.fromEntries(
  Object.entries(checksStatusMap).map(([key, value]) => [value, key]),
)

export function deserializePRs(csvContent: string, owner: Owner): PullRequest[] {
  const rows = parse(csvContent, {
    columns: true,
    skip_empty_lines: true,
    cast: true,
  })

  return rows.map((row: any) => {
    const repo = new Repo(owner, row.repo, row.defaultBranch)

    const timeline: Record<PRStage, { start: Date; end: Date | null }[] | null> = {
      [PRStage.DEV]: null,
      [PRStage.REVIEW]: null,
      [PRStage.MERGE]: null,
      [PRStage.IDLE]: null,
      [PRStage.INACTIVE]: null,
    }

    // Get the devStart time from the first dev period
    const devPeriods = row[PRStage.DEV]?.split('|')
    if (!devPeriods?.length) {
      throw new Error(`PR ${row.pr} has no dev periods`)
    }

    // First period must be a DEV period with absolute timestamp
    const absoluteStart = Number(devPeriods[0]) * 1000
    const devStart = new Date(absoluteStart)

    // Parse timeline data for each stage
    Object.values(PRStage).forEach((stage) => {
      const stagePeriods = row[stage]
      if (!stagePeriods) return

      const pairs = stagePeriods.split('|')
      const items: { start: Date; end: Date | null }[] = []

      for (let i = 0; i < pairs.length; i += 2) {
        const start = pairs[i]
        const end = pairs[i + 1]

        if (stage === PRStage.DEV && i === 0) {
          // First dev period must start with absolute timestamp
          const startTime = Number(start) * 1000
          if (startTime !== absoluteStart) {
            throw new Error(`PR ${row.pr} first DEV period doesn't start with absolute timestamp`)
          }
          items.push({
            start: new Date(startTime),
            end: end ? new Date(absoluteStart + Number(end) * 1000) : null,
          })
        } else {
          // All other timestamps are relative to first DEV start
          items.push({
            start: new Date(absoluteStart + Number(start) * 1000),
            end: end ? new Date(absoluteStart + Number(end) * 1000) : null,
          })
        }
      }

      if (items.length > 0) {
        timeline[stage] = items
      }
    })

    return new PullRequest(
      {
        repo,
        branch: row.baseBranch || row.defaultBranch,
      },
      Boolean(row.crossRepository),
      row.pr,
      row.title,
      Boolean(row.draft),
      {
        username: row.author,
        association: reverseAuthorAssociationMap[row.authorAssociation] as CommentAuthorAssociation,
        type: reverseAuthorTypeMap[row.authorType] as AuthorType,
      },
      (reverseReviewDecisionMap[row.reviewDecision] as PullRequestReviewDecision) || null,
      (reverseChecksStatusMap[row.checksStatus] as StatusState) || null,
      {
        filesCount: row.filesCount,
        linesCount: row.linesCount,
        nonGeneratedLinesCount: row.nonGeneratedLinesCount,
      },
      new Date(devStart.getTime() + Number(row.createdAt) * 1000),
      row.publishedAt ? new Date(devStart.getTime() + Number(row.publishedAt) * 1000) : null,
      row.mergedAt ? new Date(devStart.getTime() + Number(row.mergedAt) * 1000) : null,
      row.closedAt ? new Date(devStart.getTime() + Number(row.closedAt) * 1000) : null,
      timeline,
    )
  })
}
