import * as OctokitSchema from '@octokit/graphql-schema'
import { Repo } from '@shared/github/repo.js'
import { GHAuthor } from '@shared/github/githubTypes.js'
import { Owner } from './owner'

const DEFAULT_BRANCHES = ['main', 'master', 'develop']
const BOT = 'Bot'

type PRSide = {
  repo: Repo
  branch: string
}

export enum PRStage {
  DEV = 'dev',
  PICKUP = 'pickup',
  REVIEW = 'review',
  MERGE = 'merge',
}

type PRAuthor = {
  username: string | null
  association: OctokitSchema.CommentAuthorAssociation
  type: GHAuthor['__typename'] | null
}

export class PullRequest {
  constructor(
    public readonly base: PRSide,
    public readonly isCrossRepository: boolean,
    public readonly number: number,
    public readonly title: string,
    public readonly isDraft: boolean,
    public readonly author: PRAuthor,
    public readonly reviewDecision: OctokitSchema.PullRequestReviewDecision | null,
    public readonly checksStatus: OctokitSchema.StatusState | null,
    public readonly changes: {
      filesCount: number
      linesCount: number
      nonGeneratedLinesCount: number
    },
    public readonly createdAt: Date,
    public readonly publishedAt: Date | null,
    public readonly mergedAt: Date | null,
    public readonly closedAt: Date | null,
    public readonly timeline: Record<
      PRStage,
      | {
          start: Date
          end: Date | null
        }[]
      | null
    >,
  ) {}

  get isOpen(): boolean {
    return this.closedAt === null
  }

  get isMerged(): boolean {
    return this.mergedAt !== null
  }

  get isClosed(): boolean {
    return this.closedAt !== null
  }

  get isStacked(): boolean {
    return (
      !this.isCrossRepository &&
      this.base.branch !== this.base.repo.defaultBranch &&
      !DEFAULT_BRANCHES.includes(this.base.branch)
    )
  }

  get isApproved(): boolean {
    return this.reviewDecision === 'APPROVED'
  }

  get isFromMember(): boolean {
    return ['MEMBER', 'OWNER'].includes(this.author.association)
  }

  get isFromBot(): boolean {
    return this.author.type === BOT
  }

  get nonGeneratedLinesPercent(): number {
    return this.changes.nonGeneratedLinesCount / this.changes.linesCount
  }

  get githubUrl(): string {
    return `https://github.com/${this.base.repo.fullName}/pull/${this.number}`
  }

  toJSON() {
    return {
      ...this,
      base: {
        ...this.base,
        repo: {
          ...this.base.repo,
          owner: this.base.repo.owner.toJSON(),
        },
      },
      timeline: Object.fromEntries(
        Object.entries(this.timeline).map(([stage, items]) => [
          stage,
          items?.map((item) => ({
            start: item.start.toISOString(),
            end: item.end?.toISOString() ?? null,
          })) ?? null,
        ]),
      ),
    }
  }

  static fromJSON(json: any) {
    return new PullRequest(
      {
        repo: new Repo(
          new Owner(json.base.repo.owner.username, json.base.repo.owner.type),
          json.base.repo.name,
          json.base.repo.defaultBranch,
        ),
        branch: json.base.branch,
      },
      json.isCrossRepository,
      json.number,
      json.title,
      json.isDraft,
      json.author,
      json.reviewDecision,
      json.checksStatus,
      json.changes,
      new Date(json.createdAt),
      json.publishedAt ? new Date(json.publishedAt) : null,
      json.mergedAt ? new Date(json.mergedAt) : null,
      json.closedAt ? new Date(json.closedAt) : null,
      Object.fromEntries(
        Object.entries(json.timeline ?? {}).map(([stage, items]) => [
          stage,
          (items as any[])?.map((item) => ({
            start: new Date(item.start),
            end: item.end ? new Date(item.end) : null,
          })) ?? null,
        ]),
      ) as Record<
        PRStage,
        | {
            start: Date
            end: Date | null
          }[]
        | null
      >,
    )
  }
}
