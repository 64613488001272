import { Owner } from './owner.js'

export const DEFAULT_BRANCH_NAME = 'main'

export class Repo {
  constructor(
    public readonly owner: Owner,
    public readonly name: string,
    public readonly defaultBranch: string = DEFAULT_BRANCH_NAME,
  ) {}
  get fullName(): string {
    return `${this.owner.username}/${this.name}`
  }
}
