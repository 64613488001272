import React from 'react'

interface ToggleFilterProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}

const ToggleFilter: React.FC<ToggleFilterProps> = ({ label, checked, onChange }) => {
  return (
    <label
      style={{
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
        backgroundColor: 'white',
        marginRight: '10px',
      }}
    >
      <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
      {label}
    </label>
  )
}

export default ToggleFilter
