import React, { useState, useEffect } from 'react'
import OrgMetrics from '../components/OrgMetrics'
import OrganizationSelector from '../components/OrganizationSelector'
import demoOrgs from '@shared/demoOrgs.json'

const DemoApp: React.FC = () => {
  const orgNameMap: Record<string, string> = demoOrgs

  const organizations = Object.keys(orgNameMap).sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase()),
  )

  const [selectedOrg, setSelectedOrg] = useState<string>(() => {
    const path = window.location.pathname.slice(1)
    return organizations.includes(path) ? path : 'vercel'
  })

  useEffect(() => {
    const path = window.location.pathname.slice(1)
    if (!path || !organizations.includes(path)) {
      window.history.replaceState(null, '', `/${selectedOrg}`)
    }
  }, [])

  const handleOrgChange = (org: string) => {
    setSelectedOrg(org)
    window.history.pushState(null, '', `/${org}`)
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: '200px',
          backgroundColor: '#f0f0f0',
          padding: '20px',
          height: '100vh',
          boxSizing: 'border-box',
          position: 'fixed',
          overflowY: 'auto',
        }}
      >
        <OrganizationSelector
          organizations={organizations}
          selectedOrg={selectedOrg}
          onOrgChange={handleOrgChange}
          orgNameMap={orgNameMap}
        />
      </div>
      <div
        style={{
          flex: 1,
          padding: '20px',
          marginLeft: '200px',
          width: 'calc(100% - 200px)',
          boxSizing: 'border-box',
        }}
      >
        <OrgMetrics orgSlug={selectedOrg} displayName={orgNameMap[selectedOrg]} />
      </div>
    </div>
  )
}

export default DemoApp
