import React, { useState, useRef, useEffect } from 'react'

export interface Option {
  value: string
  label: string
  count: number
}

interface BaseDropdownFilterProps {
  options: Option[]
  selectedValues: string[]
  onSelectionChange: (newSelection: string[]) => void
  renderTrigger: (isOpen: boolean) => React.ReactNode
}

const BaseDropdownFilter: React.FC<BaseDropdownFilterProps> = ({
  options,
  selectedValues,
  onSelectionChange,
  renderTrigger,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => setShowDropdown(!showDropdown)

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }}>
      <div onClick={toggleDropdown}>{renderTrigger(showDropdown)}</div>
      {showDropdown && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            zIndex: 1000,
            maxHeight: '200px',
            overflowY: 'auto',
            width: '250px',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          }}
        >
          {options.map((option) => (
            <div key={option.value}>
              <label style={{ display: 'block', padding: '5px' }}>
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option.value)}
                  onChange={() => {
                    const newSelection =
                      selectedValues.includes(option.value) ?
                        selectedValues.filter((v) => v !== option.value)
                      : [...selectedValues, option.value]
                    onSelectionChange(newSelection)
                  }}
                />{' '}
                {option.label} ({option.count})
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default BaseDropdownFilter
