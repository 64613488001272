{
  "adobe": "Adobe",
  "algolia": "Algolia",
  "ansible": "Ansible",
  "apache": "Apache Foundation",
  "apple": "Apple",
  "aws": "AWS",
  "brave": "Brave",
  "cloudflare": "Cloudflare",
  "discourse": "Discourse",
  "django": "Django",
  "docker": "Docker",
  "eclipse": "Eclipse",
  "elastic": "Elastic",
  "electron": "Electron",
  "facebook": "Meta",
  "getsentry": "Sentry",
  "github": "GitHub",
  "google": "Google",
  "guardian": "The Guardian",
  "hashicorp": "HashiCorp",
  "homebrew": "Homebrew",
  "ibm": "IBM",
  "mozilla": "Mozilla",
  "mdn": "MDN",
  "metabase": "Metabase",
  "microsoft": "Microsoft",
  "netflix": "Netflix",
  "nodejs": "Node.js",
  "openai": "OpenAI",
  "OSGeo": "OSGeo",
  "paypal": "PayPal",
  "php": "PHP",
  "rails": "Ruby on Rails",
  "redis": "Redis",
  "ruby": "Ruby",
  "sindresorhus": "Sindre Sorhus",
  "square": "Square",
  "stripe": "Stripe",
  "symfony": "Symfony",
  "twitter": "X",
  "uber": "Uber",
  "vercel": "Vercel",
  "vim": "Vim",
  "wordpress": "WordPress",
  "yelp": "Yelp"
}
