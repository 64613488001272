import { parse } from 'csv-parse/browser/esm/sync'
import {
  CommentAuthorAssociation,
  PullRequestReviewDecision,
  StatusState,
} from '@octokit/graphql-schema'
import { PullRequest, PRStage } from '@shared/github/pr.js'
import { Repo } from '@shared/github/repo.js'
import { Owner } from '@shared/github/owner.js'
import { AuthorType } from '@shared/github/githubTypes.js'
import {
  authorAssociationMap,
  authorTypeMap,
  reviewDecisionMap,
  checksStatusMap,
} from './serializePRs.js'

// Reverse mappings for deserialization
const reverseAuthorAssociationMap = Object.fromEntries(
  Object.entries(authorAssociationMap).map(([key, value]) => [value, key]),
)

const reverseAuthorTypeMap = Object.fromEntries(
  Object.entries(authorTypeMap).map(([key, value]) => [value, key]),
)

const reverseReviewDecisionMap = Object.fromEntries(
  Object.entries(reviewDecisionMap).map(([key, value]) => [value, key]),
)

const reverseChecksStatusMap = Object.fromEntries(
  Object.entries(checksStatusMap).map(([key, value]) => [value, key]),
)

export function deserializePRs(csvContent: string, owner: Owner): PullRequest[] {
  const rows = parse(csvContent, {
    columns: true,
    skip_empty_lines: true,
    cast: true,
  })

  return rows.map((row: any) => {
    const repo = new Repo(owner, row.repo, row.defaultBranch)

    const timeline: Record<PRStage, { start: Date; end: Date | null }[] | null> = {
      [PRStage.DEV]: null,
      [PRStage.PICKUP]: null,
      [PRStage.REVIEW]: null,
      [PRStage.MERGE]: null,
    }

    // Get the devStart time first
    const devStart = new Date(Number(row.devStart) * 1000)

    Object.values(PRStage).forEach((stage) => {
      const stageItems: { start: Date; end: Date | null }[] = []
      let i = 0
      while (row[`${stage}Start${i || ''}`] !== undefined) {
        const start = new Date(Number(row[`${stage}Start${i || ''}`]) * 1000 + devStart.getTime())
        const end =
          row[`${stage}End${i || ''}`] ?
            new Date(Number(row[`${stage}End${i || ''}`]) * 1000 + devStart.getTime())
          : null
        stageItems.push({ start, end })
        i++
      }
      if (stageItems.length > 0) {
        timeline[stage] = stageItems
      }
    })

    timeline.dev![0].start = devStart

    return new PullRequest(
      {
        repo,
        branch: row.baseBranch || row.defaultBranch,
      },
      Boolean(row.crossRepository),
      row.pr,
      row.title,
      Boolean(row.draft),
      {
        username: row.author,
        association: reverseAuthorAssociationMap[row.authorAssociation] as CommentAuthorAssociation,
        type: reverseAuthorTypeMap[row.authorType] as AuthorType,
      },
      (reverseReviewDecisionMap[row.reviewDecision] as PullRequestReviewDecision) || null,
      (reverseChecksStatusMap[row.checksStatus] as StatusState) || null,
      {
        filesCount: row.filesCount,
        linesCount: row.linesCount,
        nonGeneratedLinesCount: row.nonGeneratedLinesCount,
      },
      new Date(Number(row.createdAt) * 1000 + devStart.getTime()),
      row.publishedAt ? new Date(Number(row.publishedAt) * 1000 + devStart.getTime()) : null,
      row.mergedAt ? new Date(Number(row.mergedAt) * 1000 + devStart.getTime()) : null,
      row.closedAt ? new Date(Number(row.closedAt) * 1000 + devStart.getTime()) : null,
      timeline,
    )
  })
}
