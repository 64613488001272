import React from 'react'
import CheckboxDropdownFilter from './CheckboxDropdownFilter'
import ButtonDropdownFilter from './ButtonDropdownFilter'
import ToggleFilter from './ToggleFilter'
import SelectFilter from './SelectFilter'
import { authorAssociationMap } from '@shared/serialization/serializePRs'
import { SortOption } from './OrgMetrics'

type UserAssociation = keyof typeof authorAssociationMap

interface PRFiltersProps {
  repoOptions: { name: string; count: number }[]
  authorOptions: { name: string; count: number }[]
  associationCounts: Record<UserAssociation, number>
  selectedRepos: string[]
  selectedAuthors: string[]
  selectedAssociations: UserAssociation[]
  includeBots: boolean
  prState: 'all' | 'open' | 'closed' | 'merged'
  sortOption: 'recent' | 'slowest' | 'quickest'
  setSelectedRepos: (repos: string[]) => void
  setSelectedAuthors: (authors: string[]) => void
  setSelectedAssociations: (associations: UserAssociation[]) => void
  handleIncludeBotsChange: (include: boolean) => void
  setPrState: (state: 'all' | 'open' | 'closed' | 'merged') => void
  setSortOption: (event: SortOption) => void
}

const PRFilters: React.FC<PRFiltersProps> = ({
  repoOptions,
  authorOptions,
  associationCounts,
  selectedRepos,
  selectedAuthors,
  selectedAssociations,
  includeBots,
  prState,
  sortOption,
  setSelectedRepos,
  setSelectedAuthors,
  setSelectedAssociations,
  handleIncludeBotsChange,
  setPrState,
  setSortOption,
}) => {
  const normalizedAssociationNames: Record<UserAssociation, string> = {
    MEMBER: 'Member',
    COLLABORATOR: 'Collaborator',
    CONTRIBUTOR: 'Contributor',
    NONE: 'No association',
    OWNER: 'Member',
    FIRST_TIME_CONTRIBUTOR: 'No association',
    FIRST_TIMER: 'No association',
    MANNEQUIN: 'No association',
  }

  const uniqueAssociations: UserAssociation[] = ['MEMBER', 'COLLABORATOR', 'CONTRIBUTOR', 'NONE']

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        position: 'relative',
      }}
    >
      <CheckboxDropdownFilter
        options={repoOptions
          .filter((repo) => repo.count > 0)
          .map((repo) => ({ value: repo.name, label: repo.name, count: repo.count }))}
        selectedValues={selectedRepos}
        onSelectionChange={setSelectedRepos}
        placeholder="Filter repositories"
      />
      <CheckboxDropdownFilter
        options={authorOptions
          .filter((author) => author.count > 0)
          .map((author) => ({ value: author.name, label: author.name, count: author.count }))}
        selectedValues={selectedAuthors}
        onSelectionChange={setSelectedAuthors}
        placeholder="Filter by author"
      />
      <ButtonDropdownFilter
        options={uniqueAssociations
          .filter((association) => (associationCounts[association] || 0) > 0)
          .map((association) => ({
            value: association,
            label: normalizedAssociationNames[association],
            count: associationCounts[association] || 0,
          }))}
        selectedValues={selectedAssociations}
        onSelectionChange={(newSelection) =>
          setSelectedAssociations(newSelection as UserAssociation[])
        }
        buttonText="Filter by association"
      />
      <ToggleFilter label="Include Bots" checked={includeBots} onChange={handleIncludeBotsChange} />
      <SelectFilter
        options={[
          { value: 'all', label: 'All PRs' },
          { value: 'open', label: 'Open PRs' },
          { value: 'closed', label: 'Closed (Unmerged) PRs' },
          { value: 'merged', label: 'Merged PRs' },
        ]}
        value={prState}
        onChange={(value) => setPrState(value as 'all' | 'open' | 'closed' | 'merged')}
      />
      <SelectFilter
        options={[
          { value: 'recent', label: 'Most recent first' },
          { value: 'slowest', label: 'Slowest first' },
          { value: 'quickest', label: 'Quickest first' },
        ]}
        value={sortOption}
        onChange={(value) => setSortOption(value as SortOption)}
      />
    </div>
  )
}

export default PRFilters
