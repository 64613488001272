import React from 'react'

interface Option {
  value: string
  label: string
}

interface SelectFilterProps {
  options: Option[]
  value: string
  onChange: (value: string) => void
}

const SelectFilter: React.FC<SelectFilterProps> = ({ options, value, onChange }) => {
  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
        backgroundColor: 'white',
        marginRight: '10px',
      }}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

export default SelectFilter
