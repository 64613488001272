import { stringify } from 'csv-stringify/sync'
import * as OctokitSchema from '@octokit/graphql-schema'
import { PullRequest, PRStage } from '@shared/github/pr.js'
import { AuthorType } from '@shared/github/githubTypes.js'

export const authorAssociationMap: Record<OctokitSchema.CommentAuthorAssociation, number | ''> = {
  MEMBER: '',
  OWNER: 1,
  COLLABORATOR: 2,
  CONTRIBUTOR: 3,
  FIRST_TIME_CONTRIBUTOR: 4,
  NONE: 5,
  FIRST_TIMER: 6,
  MANNEQUIN: 7,
}
const authorAssociationToNumber = (
  association: OctokitSchema.CommentAuthorAssociation,
): number | '' => authorAssociationMap[association] ?? 0

export const authorTypeMap: Record<AuthorType, number | ''> = {
  User: '',
  Organization: 1,
  Bot: 2,
  Mannequin: 3,
  EnterpriseUserAccount: 4,
}
const authorTypeToNumber = (type: AuthorType): number | '' => authorTypeMap[type] ?? 0

export const reviewDecisionMap: Record<OctokitSchema.PullRequestReviewDecision, number | ''> = {
  APPROVED: '',
  CHANGES_REQUESTED: 1,
  REVIEW_REQUIRED: 2,
}
const reviewDecisionToNumber = (
  decision: OctokitSchema.PullRequestReviewDecision | null,
): number | '' => (decision ? (reviewDecisionMap[decision] ?? 0) : 0)

export const checksStatusMap: Record<OctokitSchema.StatusState, number | ''> = {
  SUCCESS: '',
  FAILURE: 1,
  PENDING: 2,
  EXPECTED: 3,
  ERROR: 4,
}
const checksStatusToNumber = (status: OctokitSchema.StatusState | null): number | '' =>
  status ? checksStatusMap[status] || 0 : 0

export function serializePRs(prs: PullRequest[]): string {
  const baseHeaders = [
    'repo',
    'pr',
    'defaultBranch',
    'baseBranch',
    'title',
    'author',
    'authorAssociation',
    'authorType',
    'reviewDecision',
    'checksStatus',
    'filesCount',
    'linesCount',
    'nonGeneratedLinesCount',
    'createdAt',
    'publishedAt',
    'mergedAt',
    'closedAt',
    'crossRepository',
    'approved',
    'draft',
  ]

  const maxTimelineItems: Record<PRStage, number> = Object.values(PRStage).reduce(
    (acc, stage) => {
      acc[stage] = Math.max(...prs.map((pr) => pr.timeline[stage]?.length || 0))
      return acc
    },
    {} as Record<PRStage, number>,
  )

  const timelineHeaders: string[] = []
  Object.entries(maxTimelineItems).forEach(([stage, maxItems]) => {
    for (let i = 0; i < maxItems; i++) {
      const suffix = i === 0 ? '' : i.toString()
      timelineHeaders.push(`${stage}Start${suffix}`, `${stage}End${suffix}`)
    }
  })

  const headers = [...baseHeaders, ...timelineHeaders]

  const rows = prs.map((pr) => {
    const devStart = pr.timeline.dev![0].start
    const baseRow = [
      pr.base.repo.name,
      pr.number,
      pr.base.repo.defaultBranch,
      pr.base.branch === pr.base.repo.defaultBranch ? '' : pr.base.branch,
      pr.title.trim().slice(0, 200),
      pr.author.username,
      authorAssociationToNumber(pr.author.association),
      authorTypeToNumber(pr.author.type as AuthorType),
      reviewDecisionToNumber(pr.reviewDecision),
      checksStatusToNumber(pr.checksStatus),
      pr.changes.filesCount,
      pr.changes.linesCount,
      pr.changes.nonGeneratedLinesCount,
      getRelativeTimestamp(pr.createdAt, devStart),
      getRelativeTimestamp(pr.publishedAt, devStart),
      getRelativeTimestamp(pr.mergedAt, devStart),
      getRelativeTimestamp(pr.closedAt, devStart),
      pr.isCrossRepository ? 1 : '',
      pr.isApproved ? 1 : '',
      pr.isDraft ? 1 : '',
    ]

    const timelineData: (number | '')[] = []
    Object.entries(maxTimelineItems).forEach(([stage, maxItems]) => {
      const stageItems = pr.timeline[stage as PRStage] || []
      for (let i = 0; i < maxItems; i++) {
        if (i < stageItems.length) {
          if (stage === PRStage.DEV && i === 0) {
            timelineData.push(
              Math.floor(devStart.getTime() / 1000),
              getRelativeTimestamp(stageItems[i].end, devStart),
            )
          } else {
            timelineData.push(
              getRelativeTimestamp(stageItems[i].start, devStart),
              getRelativeTimestamp(stageItems[i].end, devStart),
            )
          }
        } else {
          timelineData.push('', '')
        }
      }
    })

    return [...baseRow, ...timelineData]
  })

  const csvContent = stringify([headers, ...rows], {
    header: false,
    quoted: false,
    quote: '"',
    escape: '"',
    quoted_match: /[,"\n\r]/,
  })

  return csvContent
}

function getRelativeTimestamp(date: Date | null | undefined, baseTimestamp: Date): number | '' {
  if (date) {
    return Math.floor((date.getTime() - baseTimestamp.getTime()) / 1000)
  }
  return ''
}
