import { stringify } from 'csv-stringify/sync'
import * as OctokitSchema from '@octokit/graphql-schema'
import { PullRequest, PRStage } from '@shared/github/pr.js'
import { AuthorType } from '@shared/github/githubTypes.js'

export const authorAssociationMap: Record<OctokitSchema.CommentAuthorAssociation, number | ''> = {
  MEMBER: '',
  OWNER: 1,
  COLLABORATOR: 2,
  CONTRIBUTOR: 3,
  FIRST_TIME_CONTRIBUTOR: 4,
  NONE: 5,
  FIRST_TIMER: 6,
  MANNEQUIN: 7,
}
const authorAssociationToNumber = (
  association: OctokitSchema.CommentAuthorAssociation,
): number | '' => authorAssociationMap[association] ?? 0

export const authorTypeMap: Record<AuthorType, number | ''> = {
  User: '',
  Organization: 1,
  Bot: 2,
  Mannequin: 3,
  EnterpriseUserAccount: 4,
}
const authorTypeToNumber = (type: AuthorType): number | '' => authorTypeMap[type] ?? 0

export const reviewDecisionMap: Record<OctokitSchema.PullRequestReviewDecision, number | ''> = {
  APPROVED: '',
  CHANGES_REQUESTED: 1,
  REVIEW_REQUIRED: 2,
}
const reviewDecisionToNumber = (
  decision: OctokitSchema.PullRequestReviewDecision | null,
): number | '' => (decision ? (reviewDecisionMap[decision] ?? 0) : 0)

export const checksStatusMap: Record<OctokitSchema.StatusState, number | ''> = {
  SUCCESS: '',
  FAILURE: 1,
  PENDING: 2,
  EXPECTED: 3,
  ERROR: 4,
}
const checksStatusToNumber = (status: OctokitSchema.StatusState | null): number | '' =>
  status ? checksStatusMap[status] || 0 : 0

export function serializePRs(prs: PullRequest[]): string {
  const baseHeaders = [
    'repo',
    'pr',
    'defaultBranch',
    'baseBranch',
    'title',
    'author',
    'authorAssociation',
    'authorType',
    'reviewDecision',
    'checksStatus',
    'filesCount',
    'linesCount',
    'nonGeneratedLinesCount',
    'createdAt',
    'publishedAt',
    'mergedAt',
    'closedAt',
    'crossRepository',
    'approved',
    'draft',
  ]

  const timelineHeaders = Object.values(PRStage).map((stage) => stage)
  const headers = [...baseHeaders, ...timelineHeaders]

  const rows = prs.map((pr) => {
    // Find the earliest timestamp from any period
    let devStart = pr.createdAt // Start with createdAt as base
    Object.values(PRStage).forEach((stage) => {
      const periods = pr.timeline[stage]
      if (periods && periods.length > 0) {
        const firstPeriodStart = periods[0].start
        if (firstPeriodStart.getTime() < devStart.getTime()) {
          devStart = firstPeriodStart
        }
      }
    })

    // Ensure we have a DEV period starting at devStart
    const devPeriods = pr.timeline[PRStage.DEV] || []
    if (devPeriods.length === 0) {
      // Find the next period after devStart from any stage
      let nextPeriodStart: Date | null = null
      Object.values(PRStage).forEach((stage) => {
        const periods = pr.timeline[stage]
        if (periods && periods.length > 0) {
          const firstPeriodAfterDev = periods.find((p) => p.start.getTime() > devStart.getTime())
          if (
            firstPeriodAfterDev &&
            (!nextPeriodStart || firstPeriodAfterDev.start.getTime() < nextPeriodStart.getTime())
          ) {
            nextPeriodStart = firstPeriodAfterDev.start
          }
        }
      })

      // Create a DEV period from devStart to the next period (if any)
      pr.timeline[PRStage.DEV] = [
        {
          start: devStart,
          end: nextPeriodStart,
        },
      ]
    } else if (devPeriods[0].start.getTime() > devStart.getTime()) {
      // If first DEV period starts after devStart, prepend a new period
      let nextPeriodStart = devPeriods[0].start
      devPeriods.unshift({
        start: devStart,
        end: nextPeriodStart,
      })
      pr.timeline[PRStage.DEV] = devPeriods
    }

    const baseRow = [
      pr.base.repo.name,
      pr.number,
      pr.base.repo.defaultBranch,
      pr.base.branch === pr.base.repo.defaultBranch ? '' : pr.base.branch,
      pr.title.trim().slice(0, 200),
      pr.author.username,
      authorAssociationToNumber(pr.author.association),
      authorTypeToNumber(pr.author.type as AuthorType),
      reviewDecisionToNumber(pr.reviewDecision),
      checksStatusToNumber(pr.checksStatus),
      pr.changes.filesCount,
      pr.changes.linesCount,
      pr.changes.nonGeneratedLinesCount,
      getRelativeTimestamp(pr.createdAt, devStart),
      getRelativeTimestamp(pr.publishedAt, devStart),
      getRelativeTimestamp(pr.mergedAt, devStart),
      getRelativeTimestamp(pr.closedAt, devStart),
      pr.isCrossRepository ? 1 : '',
      pr.isApproved ? 1 : '',
      pr.isDraft ? 1 : '',
    ]

    const timelineData = Object.values(PRStage).map((stage) => {
      const stageItems = pr.timeline[stage] || []
      if (stageItems.length === 0) return ''

      // Sort periods by start time to ensure correct order
      const sortedItems = [...stageItems].sort((a, b) => a.start.getTime() - b.start.getTime())

      if (stage === PRStage.DEV) {
        // First DEV period must use absolute timestamp and start at devStart
        if (sortedItems[0].start.getTime() !== devStart.getTime()) {
          throw new Error(`PR ${pr.number} first DEV period doesn't start at devStart`)
        }

        const absoluteStart = Math.floor(devStart.getTime() / 1000)
        const firstPeriod = `${absoluteStart}|${getRelativeTimestamp(sortedItems[0].end, devStart)}`

        // All other periods must be relative
        const remainingPeriods = sortedItems
          .slice(1)
          .map(
            (item) =>
              `${getRelativeTimestamp(item.start, devStart)}|${getRelativeTimestamp(item.end, devStart)}`,
          )
          .join('|')

        return remainingPeriods ? `${firstPeriod}|${remainingPeriods}` : firstPeriod
      }

      // All other stages use relative timestamps
      return sortedItems
        .map(
          (item) =>
            `${getRelativeTimestamp(item.start, devStart)}|${getRelativeTimestamp(item.end, devStart)}`,
        )
        .join('|')
    })

    return [...baseRow, ...timelineData]
  })

  const csvContent = stringify([headers, ...rows], {
    header: false,
    quoted: false,
    quote: '"',
    escape: '"',
    quoted_match: /[,"\n\r]/,
  })

  return csvContent
}

function getRelativeTimestamp(date: Date | null | undefined, baseTimestamp: Date): number | '' {
  if (!date) return ''

  // Round both timestamps to seconds to avoid floating point issues
  const dateSeconds = Math.floor(date.getTime() / 1000)
  const baseSeconds = Math.floor(baseTimestamp.getTime() / 1000)

  // Return the number of seconds from the base timestamp
  return dateSeconds - baseSeconds
}
