type MessageType = string | number | boolean | null | undefined | Error

export const debug = (...messages: MessageType[]) => {
  if (!process?.env || process.env.DEBUG) {
    console.log(...messages)
  }
}

export const error = (...messages: MessageType[]) => {
  if (!process?.env || process.env.DEBUG) {
    console.error(...messages)
  }
}

export default debug
