import React from 'react'

interface OrganizationSelectorProps {
  organizations: string[]
  selectedOrg: string
  onOrgChange: (org: string) => void
  orgNameMap: Record<string, string>
}

const OrganizationSelector: React.FC<OrganizationSelectorProps> = ({
  organizations,
  selectedOrg,
  onOrgChange,
  orgNameMap,
}) => {
  return (
    <div>
      <h2>Organizations</h2>
      {organizations.map((org) => (
        <button
          key={org}
          onClick={() => onOrgChange(org)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '5px',
            backgroundColor: selectedOrg === org ? '#007bff' : '#ffffff',
            color: selectedOrg === org ? '#ffffff' : '#000000',
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer',
            textAlign: 'left',
          }}
        >
          {orgNameMap[org]}
        </button>
      ))}
    </div>
  )
}

export default OrganizationSelector
