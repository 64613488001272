import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import * as OctokitSchema from '@octokit/graphql-schema'
import { PullRequest, PRStage } from '@shared/github/pr'
import { authorAssociationMap } from '@shared/serialization/serializePRs'
import PaginationControls from './PaginationControls'
import PRFilters from './PRFilters'
import { fetchOrgData } from '../demo/data/fetchOrgData'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type UserAssociation = keyof typeof authorAssociationMap

interface PRChartData {
  prNumber: number
  repo: string
  url: string
  createdAt: Date
  title: string
  [PRStage.DEV]: number
  [PRStage.PICKUP]: number
  [PRStage.REVIEW]: number
  [PRStage.MERGE]: number
  totalTime: number
}

const ITEMS_PER_PAGE = 100

const stageColors = {
  [PRStage.DEV]: 'rgba(255, 99, 132, 0.8)',
  [PRStage.PICKUP]: 'rgba(54, 162, 235, 0.8)',
  [PRStage.REVIEW]: 'rgba(75, 192, 192, 0.8)',
  [PRStage.MERGE]: 'rgba(153, 102, 255, 0.8)',
}

interface OrgMetricsProps {
  orgSlug: string
  displayName: string
}

export type SortOption = 'recent' | 'slowest' | 'quickest'

const OrgMetrics: React.FC<OrgMetricsProps> = ({ orgSlug, displayName }) => {
  const [prs, setPRs] = useState<PullRequest[]>([])
  const [selectedRepos, setSelectedRepos] = useState<string[]>([])
  const [selectedAuthors, setSelectedAuthors] = useState<string[]>([])
  const [selectedAssociations, setSelectedAssociations] = useState<UserAssociation[]>([])
  const [includeBots, setIncludeBots] = useState(false)
  const [prState, setPrState] = useState<'all' | 'open' | 'closed' | 'merged'>('all')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortOption, setSortOption] = useState<'recent' | 'slowest' | 'quickest'>('slowest')
  const [key] = useState(0)

  // Track the initial load
  const isInitialLoad = useRef(true)
  const previousOrg = useRef(orgSlug)

  useEffect(() => {
    // Only parse URL parameters on initial load
    if (isInitialLoad.current) {
      const searchParams = new URLSearchParams(window.location.search)

      const repos = searchParams.get('repos')?.split('_').sort() || []
      const authors = searchParams.get('authors')?.split('_').sort() || []
      const associations =
        (searchParams.get('associations')?.split('_').sort() as UserAssociation[]) || []
      const includeBots = searchParams.get('includeBots') === 'true'
      const prState = (searchParams.get('prState') as 'all' | 'open' | 'closed' | 'merged') || 'all'
      const sortOption = (searchParams.get('sortOption') as SortOption) || 'slowest'
      const page = parseInt(searchParams.get('page') || '1', 10)

      setSelectedRepos(repos)
      setSelectedAuthors(authors)
      setSelectedAssociations(associations)
      setIncludeBots(includeBots)
      setPrState(prState)
      setSortOption(sortOption)
      setCurrentPage(page)

      isInitialLoad.current = false
    } else if (previousOrg.current !== orgSlug) {
      // Reset filters when org changes
      setSelectedRepos([])
      setSelectedAuthors([])
      setSelectedAssociations([])
      setIncludeBots(false)
      setPrState('all')
      setSortOption('slowest')
      setCurrentPage(1)
    }

    previousOrg.current = orgSlug
  }, [orgSlug]) // Now depends on orgSlug to detect org changes

  useEffect(() => {
    setIsLoading(true)
    setError(null)
    fetchOrgData(orgSlug.toLowerCase())
      .then((deserializedPRs) => {
        setPRs(deserializedPRs)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching or deserializing data:', error)
        setError('Failed to load PR data. Please try again later.')
        setIsLoading(false)
      })
  }, [orgSlug])

  useEffect(() => {
    // Update URL when filters change
    const searchParams = new URLSearchParams()

    if (selectedRepos.length > 0) searchParams.set('repos', selectedRepos.sort().join('_'))
    if (selectedAuthors.length > 0) searchParams.set('authors', selectedAuthors.sort().join('_'))
    if (selectedAssociations.length > 0)
      searchParams.set('associations', selectedAssociations.sort().join('_'))
    if (includeBots) searchParams.set('includeBots', 'true')
    if (prState !== 'all') searchParams.set('prState', prState)
    if (sortOption !== 'slowest') searchParams.set('sortOption', sortOption)
    if (currentPage !== 1) searchParams.set('page', currentPage.toString())

    // Sort query params
    const sortedParams = new URLSearchParams([...searchParams.entries()].sort())

    const newUrl = `/${orgSlug}${sortedParams.toString() ? '?' + sortedParams.toString() : ''}`
    window.history.pushState({ path: newUrl }, '', newUrl)
  }, [
    selectedRepos,
    selectedAuthors,
    selectedAssociations,
    includeBots,
    prState,
    sortOption,
    currentPage,
    orgSlug,
  ])

  const mapUserAssociation = (
    association: OctokitSchema.CommentAuthorAssociation,
  ): UserAssociation => {
    if (association === 'OWNER') return 'MEMBER'
    if (association === 'FIRST_TIME_CONTRIBUTOR' || association === 'FIRST_TIMER') return 'NONE'
    return association as UserAssociation
  }

  const repoOptions = useMemo(() => {
    const repoCounts = prs.reduce(
      (acc, pr) => {
        acc[pr.base.repo.name] = (acc[pr.base.repo.name] || 0) + 1
        return acc
      },
      {} as Record<string, number>,
    )

    return Object.entries(repoCounts)
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
  }, [prs])

  const authorOptions = useMemo(() => {
    const authorCounts = prs.reduce(
      (acc, pr) => {
        if (includeBots || !pr.isFromBot) {
          const username = pr.author?.username || 'Unknown'
          acc[username] = (acc[username] || 0) + 1
        }
        return acc
      },
      {} as Record<string, number>,
    )

    return Object.entries(authorCounts)
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
  }, [prs, includeBots])

  const associationCounts = useMemo(() => {
    return prs.reduce(
      (acc, pr) => {
        const association = mapUserAssociation(pr.author.association)
        acc[association] = (acc[association] || 0) + 1
        return acc
      },
      {} as Record<UserAssociation, number>,
    )
  }, [prs])

  const calculateTimeInHours = (start: Date, end: Date | null) => {
    if (!end) return 0
    return Math.max(0, (end.getTime() - start.getTime()) / (1000 * 60 * 60))
  }

  const calculateTotalTime = (pr: PullRequest) => {
    const stages = Object.values(PRStage)
    let earliestTimestamp = Infinity
    let latestTimestamp = Date.now()

    stages.forEach((stage) => {
      pr.timeline[stage]?.forEach(({ start, end }) => {
        earliestTimestamp = Math.min(earliestTimestamp, start.getTime())
        if (end) {
          latestTimestamp = Math.max(latestTimestamp, end.getTime())
        }
      })
    })

    return latestTimestamp !== -Infinity && earliestTimestamp !== Infinity ?
        (latestTimestamp - earliestTimestamp) / (1000 * 60 * 60)
      : 0
  }

  const calculateStageData = (pr: PullRequest) => {
    return Object.values(PRStage).reduce(
      (acc, stage) => {
        acc[stage] =
          pr.timeline[stage]?.reduce(
            (total, { start, end }) => total + calculateTimeInHours(start, end || new Date()),
            0,
          ) || 0
        return acc
      },
      {} as Record<PRStage, number>,
    )
  }

  const handleIncludeBotsChange = useCallback((include: boolean) => {
    setIncludeBots(include)
    setCurrentPage(1) // Reset to the first page when changing filters
  }, [])

  const handleSortChange = (value: SortOption) => {
    setSortOption(value)
    setCurrentPage(1) // Reset to the first page when sorting changes
  }

  const handleRepoChange = (repos: string[]) => {
    setSelectedRepos(repos)
    setCurrentPage(1)
  }

  const handleAuthorChange = (authors: string[]) => {
    setSelectedAuthors(authors)
    setCurrentPage(1)
  }

  const handleAssociationChange = (associations: UserAssociation[]) => {
    setSelectedAssociations(associations)
    setCurrentPage(1)
  }

  const handlePrStateChange = (state: 'all' | 'open' | 'closed' | 'merged') => {
    setPrState(state)
    setCurrentPage(1)
  }

  const { chartData, options, totalPages, totalFilteredPRs } = useMemo(() => {
    const filteredPRs = prs.filter(
      (pr) =>
        (selectedRepos.length === 0 || selectedRepos.includes(pr.base.repo.name)) &&
        (selectedAuthors.length === 0 ||
          (pr.author?.username && selectedAuthors.includes(pr.author.username))) &&
        (selectedAssociations.length === 0 ||
          selectedAssociations.includes(mapUserAssociation(pr.author.association))) &&
        (includeBots || !pr.isFromBot) &&
        (prState === 'all' ||
          (prState === 'open' ? pr.isOpen
          : prState === 'merged' ? pr.isMerged
          : prState === 'closed' ? !pr.isOpen && !pr.isMerged
          : false)),
    )

    let sortedPRs = filteredPRs
    switch (sortOption) {
      case 'recent':
        sortedPRs.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        break
      case 'slowest':
        sortedPRs.sort((a, b) => calculateTotalTime(b) - calculateTotalTime(a))
        break
      case 'quickest':
        sortedPRs.sort((a, b) => calculateTotalTime(a) - calculateTotalTime(b))
        break
    }

    const totalFilteredPRs = sortedPRs.length
    const totalPages = Math.ceil(sortedPRs.length / ITEMS_PER_PAGE)
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    const paginatedPRs = sortedPRs.slice(startIndex, endIndex)

    const data: PRChartData[] = paginatedPRs.map((pr) => {
      const stageData = calculateStageData(pr)
      const totalTime = calculateTotalTime(pr)

      return {
        prNumber: pr.number,
        repo: pr.base.repo.name,
        url: pr.githubUrl,
        createdAt: pr.createdAt,
        title: pr.title,
        ...stageData,
        totalTime,
      }
    })

    const maxTotalTime = Math.max(...data.map((d) => d.totalTime))

    const formatDuration = (hours: number) => {
      const HOURS_PER_DAY = 24
      const DAYS_PER_WEEK = 7
      const DAYS_PER_MONTH = 30.42

      const months = Math.floor(hours / (DAYS_PER_MONTH * HOURS_PER_DAY))
      hours %= DAYS_PER_MONTH * HOURS_PER_DAY
      const weeks = Math.floor(hours / (DAYS_PER_WEEK * HOURS_PER_DAY))
      hours %= DAYS_PER_WEEK * HOURS_PER_DAY
      const days = Math.floor(hours / HOURS_PER_DAY)
      hours %= HOURS_PER_DAY

      const parts = []
      if (months > 0) parts.push(`${months}mo`)
      if (weeks > 0) parts.push(`${weeks}w`)
      if (days > 0) parts.push(`${days}d`)
      if (hours > 0) parts.push(`${Math.round(hours)}h`)

      return parts.join(' ') || '0h'
    }

    const chartData = {
      labels: data.map((d) => `#${d.prNumber}`),
      datasets: Object.values(PRStage).map((stage) => ({
        label: stage,
        data: data.map((d) => ({ x: d[stage], y: `#${d.prNumber}`, totalDuration: d.totalTime })),
        backgroundColor: stageColors[stage],
        borderColor: stageColors[stage],
        borderWidth: 1,
        barPercentage: 1,
        categoryPercentage: 0.8,
      })),
    }

    const options = {
      indexAxis: 'y' as const,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
            text: 'Duration (hours)',
          },
          ticks: {
            callback: function (this: any, tickValue: number | string) {
              return formatDuration(Number(tickValue))
            },
          },
          max: maxTotalTime,
        },
        y: {
          stacked: true,
          title: {
            display: true,
          },
          text: 'Pull Request',
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context: any) => {
              const index = context[0].dataIndex
              return `#${data[index].prNumber} - ${data[index].title}`
            },
            label: () => '', // Remove default label
            afterBody: (context: any) => {
              const index = context[0].dataIndex
              const pr = data[index]
              return Object.values(PRStage)
                .map((stage) => `${stage}: ${formatDuration(pr[stage])}`)
                .concat(`Total: ${formatDuration(pr.totalTime)}`)
            },
          },
        },
        legend: {
          position: 'top' as const,
        },
      },
      onClick: (_: any, elements: any) => {
        if (elements.length > 0) {
          const index = elements[0].index
          window.open(data[index].url, '_blank')
        }
      },
    }

    return { chartData, options, totalPages, totalFilteredPRs }
  }, [
    prs,
    selectedRepos,
    selectedAuthors,
    selectedAssociations,
    includeBots,
    prState,
    currentPage,
    sortOption,
  ])

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
      // Scroll to top of the component
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const chartRef =
    useRef<ChartJS<'bar', { x: number; y: string; totalDuration: number }[], unknown>>(null)

  return (
    <div key={key}>
      <h1>PR Metrics - {displayName}</h1>
      <PRFilters
        repoOptions={repoOptions}
        authorOptions={authorOptions}
        associationCounts={associationCounts}
        selectedRepos={selectedRepos}
        selectedAuthors={selectedAuthors}
        selectedAssociations={selectedAssociations}
        includeBots={includeBots}
        prState={prState}
        sortOption={sortOption}
        setSelectedRepos={handleRepoChange}
        setSelectedAuthors={handleAuthorChange}
        setSelectedAssociations={handleAssociationChange}
        setPrState={handlePrStateChange}
        handleIncludeBotsChange={handleIncludeBotsChange}
        setSortOption={handleSortChange}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div>
          {isLoading ?
            <p style={{ margin: 0 }}>Loading data...</p>
          : error ?
            <p style={{ margin: 0, color: 'red' }}>{error}</p>
          : <p style={{ margin: 0 }}>
              {totalFilteredPRs > 0 ?
                `${totalFilteredPRs} pull request${totalFilteredPRs !== 1 ? 's' : ''} analysed.`
              : '0 pull requests matching the current filters.'}
            </p>
          }
        </div>
      </div>
      {!isLoading && !error && totalFilteredPRs > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <div
        style={{
          height: `${Math.min(ITEMS_PER_PAGE, chartData.labels.length) * 30 + 100}px`,
          minHeight: '400px',
          maxHeight: '3000px',
        }}
      >
        {isLoading ?
          null
        : error ?
          null
        : totalFilteredPRs > 0 ?
          <Bar data={chartData} options={options} ref={chartRef} />
        : null}
      </div>
      {!isLoading && !error && totalFilteredPRs > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  )
}

export default OrgMetrics
