import React from 'react'
import BaseDropdownFilter, { Option } from './BaseDropdownFilter'

interface ButtonDropdownFilterProps {
  options: Option[]
  selectedValues: string[]
  onSelectionChange: (newSelection: string[]) => void
  buttonText: string
}

const ButtonDropdownFilter: React.FC<ButtonDropdownFilterProps> = ({
  options,
  selectedValues,
  onSelectionChange,
  buttonText,
}) => {
  const renderTrigger = (isOpen: boolean) => (
    <button
      style={{
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
        backgroundColor: isOpen ? '#e0e0e0' : 'white',
        marginRight: '10px',
        cursor: 'pointer',
      }}
    >
      {buttonText} {isOpen ? '▲' : '▼'}
    </button>
  )

  return (
    <BaseDropdownFilter
      options={options}
      selectedValues={selectedValues}
      onSelectionChange={onSelectionChange}
      renderTrigger={renderTrigger}
    />
  )
}

export default ButtonDropdownFilter
