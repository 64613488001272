import React, { useState } from 'react'
import BaseDropdownFilter, { Option } from './BaseDropdownFilter'

interface CheckboxDropdownFilterProps {
  options: Option[]
  selectedValues: string[]
  onSelectionChange: (newSelection: string[]) => void
  placeholder: string
}

const CheckboxDropdownFilter: React.FC<CheckboxDropdownFilterProps> = ({
  options,
  selectedValues,
  onSelectionChange,
  placeholder,
}) => {
  const [filter, setFilter] = useState('')

  const filteredOptions = options
    .filter((option) => option.count > 0)
    .filter((option) => option.label.toLowerCase().includes(filter.toLowerCase()))

  const renderTrigger = () => (
    <input
      type="text"
      placeholder={placeholder}
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      style={{
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
        backgroundColor: 'white',
        marginRight: '10px',
      }}
    />
  )

  return (
    <BaseDropdownFilter
      options={filteredOptions}
      selectedValues={selectedValues}
      onSelectionChange={onSelectionChange}
      renderTrigger={renderTrigger}
    />
  )
}

export default CheckboxDropdownFilter
