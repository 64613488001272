import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import * as echarts from 'echarts/core';
import { SVGRenderer, CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  AxisPointerComponent,
  DatasetComponent,
  SingleAxisComponent,
  PolarComponent,
} from 'echarts/components';
import { UniversalTransition } from 'echarts/features';
import type { EChartsOption } from 'echarts'
import * as OctokitSchema from '@octokit/graphql-schema'
import { PullRequest, PRStage } from '@shared/github/pr'
import { authorAssociationMap } from '@shared/serialization/serializePRs'
import PaginationControls from './PaginationControls'
import PRFilters from './PRFilters'
import { error as logError } from '@shared/util/debug'
import { fetchOrgData } from '../demo/data/fetchOrgData'

type TooltipData = string | number | Date | null | undefined

// Register the required components
echarts.use([
  SVGRenderer,
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  AxisPointerComponent,
  DatasetComponent,
  SingleAxisComponent,
  PolarComponent,
  UniversalTransition,
])

type UserAssociation = keyof typeof authorAssociationMap

interface PRChartData {
  prNumber: number
  repo: string
  url: string
  createdAt: Date
  title: string
  isOpen: boolean
  periods: Array<{
    stage: PRStage
    duration: number
    start: Date
  }>
  totalTime: number
}

const ITEMS_PER_PAGE = 100

interface OrgMetricsProps {
  orgSlug: string
  displayName: string
}

export type SortOption = 'recent' | 'slowest' | 'quickest'

const OrgMetrics: React.FC<OrgMetricsProps> = ({ orgSlug, displayName }) => {
  const [prs, setPRs] = useState<PullRequest[]>([])
  const [selectedRepos, setSelectedRepos] = useState<string[]>([])
  const [selectedAuthors, setSelectedAuthors] = useState<string[]>([])
  const [selectedAssociations, setSelectedAssociations] = useState<UserAssociation[]>([])
  const [includeBots, setIncludeBots] = useState(false)
  const [prState, setPrState] = useState<'all' | 'open' | 'closed' | 'merged'>('all')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortOption, setSortOption] = useState<'recent' | 'slowest' | 'quickest'>('slowest')
  const [key] = useState(0)
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstance = useRef<echarts.ECharts | null>(null)

  // Track the initial load
  const isInitialLoad = useRef(true)
  const previousOrg = useRef(orgSlug)

  useEffect(() => {
    // Only parse URL parameters on initial load
    if (isInitialLoad.current) {
      const searchParams = new URLSearchParams(window.location.search)

      const repos = searchParams.get('repos')?.split('_').sort() || []
      const authors = searchParams.get('authors')?.split('_').sort() || []
      const associations =
        (searchParams.get('associations')?.split('_').sort() as UserAssociation[]) || []
      const includeBots = searchParams.get('includeBots') === 'true'
      const prState = (searchParams.get('prState') as 'all' | 'open' | 'closed' | 'merged') || 'all'
      const sortOption = (searchParams.get('sortOption') as SortOption) || 'slowest'
      const page = parseInt(searchParams.get('page') || '1', 10)

      setSelectedRepos(repos)
      setSelectedAuthors(authors)
      setSelectedAssociations(associations)
      setIncludeBots(includeBots)
      setPrState(prState)
      setSortOption(sortOption)
      setCurrentPage(page)

      isInitialLoad.current = false
    } else if (previousOrg.current !== orgSlug) {
      // Reset filters when org changes
      setSelectedRepos([])
      setSelectedAuthors([])
      setSelectedAssociations([])
      setIncludeBots(false)
      setPrState('all')
      setSortOption('slowest')
      setCurrentPage(1)
    }

    previousOrg.current = orgSlug
  }, [orgSlug]) // Now depends on orgSlug to detect org changes

  useEffect(() => {
    setIsLoading(true)
    setError(null)
    fetchOrgData(orgSlug.toLowerCase())
      .then((deserializedPRs) => {
        setPRs(deserializedPRs)
        setIsLoading(false)
      })
      .catch((error) => {
        logError('Error fetching or deserializing data:', error)
        setError('Failed to load PR data. Please try again later.')
        setIsLoading(false)
      })
  }, [orgSlug])

  useEffect(() => {
    // Update URL when filters change
    const searchParams = new URLSearchParams()

    if (selectedRepos.length > 0) searchParams.set('repos', selectedRepos.sort().join('_'))
    if (selectedAuthors.length > 0) searchParams.set('authors', selectedAuthors.sort().join('_'))
    if (selectedAssociations.length > 0)
      searchParams.set('associations', selectedAssociations.sort().join('_'))
    if (includeBots) searchParams.set('includeBots', 'true')
    if (prState !== 'all') searchParams.set('prState', prState)
    if (sortOption !== 'slowest') searchParams.set('sortOption', sortOption)
    if (currentPage !== 1) searchParams.set('page', currentPage.toString())

    // Sort query params
    const sortedParams = new URLSearchParams([...searchParams.entries()].sort())

    const newUrl = `/${orgSlug}${sortedParams.toString() ? '?' + sortedParams.toString() : ''}`
    window.history.pushState({ path: newUrl }, '', newUrl)
  }, [
    selectedRepos,
    selectedAuthors,
    selectedAssociations,
    includeBots,
    prState,
    sortOption,
    currentPage,
    orgSlug,
  ])

  const mapUserAssociation = (
    association: OctokitSchema.CommentAuthorAssociation,
  ): UserAssociation => {
    if (association === 'OWNER') return 'MEMBER'
    if (association === 'FIRST_TIME_CONTRIBUTOR' || association === 'FIRST_TIMER') return 'NONE'
    return association as UserAssociation
  }

  const repoOptions = useMemo(() => {
    const repoCounts = prs.reduce(
      (acc, pr) => {
        acc[pr.base.repo.name] = (acc[pr.base.repo.name] || 0) + 1
        return acc
      },
      {} as Record<string, number>,
    )

    return Object.entries(repoCounts)
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
  }, [prs])

  const authorOptions = useMemo(() => {
    const authorCounts = prs.reduce(
      (acc, pr) => {
        if (includeBots || !pr.isFromBot) {
          const username = pr.author?.username || 'Unknown'
          acc[username] = (acc[username] || 0) + 1
        }
        return acc
      },
      {} as Record<string, number>,
    )

    return Object.entries(authorCounts)
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
  }, [prs, includeBots])

  const associationCounts = useMemo(() => {
    return prs.reduce(
      (acc, pr) => {
        const association = mapUserAssociation(pr.author.association)
        acc[association] = (acc[association] || 0) + 1
        return acc
      },
      {} as Record<UserAssociation, number>,
    )
  }, [prs])

  const calculateTimeInHours = (start: Date, end: Date | null) => {
    if (!end) return 0
    return Math.max(0, Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60)))
  }

  const getTimeRange = (pr: PullRequest) => {
    const timestamps = Object.values(pr.timeline)
      .filter((stage): stage is Array<{start: Date; end: Date | null}> => stage !== null)
      .flatMap(stage => stage.map(({ start, end }) => ({
        time: start.getTime(),
        isEnd: false
      })).concat(stage.map(({ start, end }) => ({
        time: end ? end.getTime() : (pr.isOpen ? Date.now() : start.getTime()),
        isEnd: true
      }))))

    // We must have at least one period (DEV) from serialization
    const earliestTime = Math.min(...timestamps.map(t => t.time))
    const latestTime = Math.max(...timestamps.map(t => t.time))
    return { earliestTime, latestTime }
  }

  const calculateTotalTime = (pr: PullRequest) => {
    // Every PR must have at least one DEV period
    const devPeriods = pr.timeline[PRStage.DEV]
    if (!devPeriods || devPeriods.length === 0) {
      console.error('PR has no DEV periods:', pr)
      return 0
    }

    const { earliestTime, latestTime } = getTimeRange(pr)
    return calculateTimeInHours(new Date(earliestTime), new Date(latestTime))
  }

  const calculateStageData = (pr: PullRequest) => {
    // Get the overall time range for this PR
    const { earliestTime, latestTime } = getTimeRange(pr)

    // Create a timeline of all stage transitions
    type TimelineEvent = { time: number; stage: PRStage; isStart: boolean }
    const events: TimelineEvent[] = []

    Object.values(PRStage).forEach(stage => {
      const periods = pr.timeline[stage]
      if (!periods) return

      periods.forEach(({ start, end }) => {
        const endTime = end || (pr.isOpen ? new Date() : start)
        // Constrain times to PR's overall time range
        const periodStart = Math.max(start.getTime(), earliestTime)
        const periodEnd = Math.min(endTime.getTime(), latestTime)

        if (periodEnd > periodStart) {
          events.push({ time: periodStart, stage, isStart: true })
          events.push({ time: periodEnd, stage, isStart: false })
        }
      })
    })

    // Sort events chronologically
    events.sort((a, b) => a.time - b.time)

    // Calculate non-overlapping periods
    const periods: Array<{
      stage: PRStage
      duration: number
      start: Date
    }> = []

    const activeStages = new Set<PRStage>()
    let lastTime = earliestTime

    events.forEach(event => {
      if (event.time > lastTime && activeStages.size === 1) {
        // If only one stage was active, add its duration
        const stage = Array.from(activeStages)[0]
        const duration = calculateTimeInHours(
          new Date(lastTime),
          new Date(event.time)
        )
        if (duration > 0) {
          periods.push({
            stage,
            duration,
            start: new Date(lastTime)
          })
        }
      }

      if (event.isStart) {
        activeStages.add(event.stage)
      } else {
        activeStages.delete(event.stage)
      }
      lastTime = event.time
    })

    // Sort periods by start time
    return periods.sort((a, b) => a.start.getTime() - b.start.getTime())
  }

  const handleIncludeBotsChange = useCallback((include: boolean) => {
    setIncludeBots(include)
    setCurrentPage(1) // Reset to the first page when changing filters
  }, [])

  const handleSortChange = (value: SortOption) => {
    setSortOption(value)
    setCurrentPage(1) // Reset to the first page when sorting changes
  }

  const handleRepoChange = (repos: string[]) => {
    setSelectedRepos(repos)
    setCurrentPage(1)
  }

  const handleAuthorChange = (authors: string[]) => {
    setSelectedAuthors(authors)
    setCurrentPage(1)
  }

  const handleAssociationChange = (associations: UserAssociation[]) => {
    setSelectedAssociations(associations)
    setCurrentPage(1)
  }

  const handlePrStateChange = (state: 'all' | 'open' | 'closed' | 'merged') => {
    setPrState(state)
    setCurrentPage(1)
  }

  const { filteredPRs, totalPages, totalFilteredPRs } = useMemo(() => {
    // This computation runs for all PRs but only does filtering and basic calculations
    const filtered = prs.filter(
      (pr) =>
        (selectedRepos.length === 0 || selectedRepos.includes(pr.base.repo.name)) &&
        (selectedAuthors.length === 0 ||
          (pr.author?.username && selectedAuthors.includes(pr.author.username))) &&
        (selectedAssociations.length === 0 ||
          selectedAssociations.includes(mapUserAssociation(pr.author.association))) &&
        (includeBots || !pr.isFromBot) &&
        (prState === 'all' ||
          (prState === 'open' ? pr.isOpen
          : prState === 'merged' ? pr.isMerged
          : prState === 'closed' ? !pr.isOpen && !pr.isMerged
          : false))
    )

    let sorted = [...filtered]
    switch (sortOption) {
      case 'recent':
        sorted.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        break
      case 'slowest':
        sorted.sort((a, b) => calculateTotalTime(b) - calculateTotalTime(a))
        break
      case 'quickest':
        sorted.sort((a, b) => calculateTotalTime(a) - calculateTotalTime(b))
        break
    }

    return {
      filteredPRs: sorted,
      totalPages: Math.ceil(sorted.length / ITEMS_PER_PAGE),
      totalFilteredPRs: sorted.length
    }
  }, [prs, selectedRepos, selectedAuthors, selectedAssociations, includeBots, prState, sortOption])

  const { chartData, options } = useMemo(() => {
    // This computation runs only for the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    const paginatedPRs = filteredPRs.slice(startIndex, endIndex)

    const data: PRChartData[] = paginatedPRs.map((pr) => {
      const periods = calculateStageData(pr)
      const totalTime = calculateTotalTime(pr)

      return {
        prNumber: pr.number,
        repo: pr.base.repo.name,
        url: pr.githubUrl,
        createdAt: pr.createdAt,
        title: pr.title,
        isOpen: pr.isOpen,
        periods,
        totalTime,
      }
    })

    // Create series for each unique period index
    const maxPeriods = Math.max(...data.map(d => d.periods.length))
    const series: any[] = []

    // Create a series for each period
    for (let periodIndex = 0; periodIndex < maxPeriods; periodIndex++) {
      Object.values(PRStage).forEach(stage => {
        series.push({
          name: stage,
          type: 'bar' as const,
          stack: 'total',
          itemStyle: {
            color: getStageColor(stage)
          },
          data: data.map(pr => {
            const period = pr.periods[periodIndex]
            return period?.stage === stage ? period.duration : 0
          })
        })
      })
    }

    const echartsOption: EChartsOption = {
      aria: {
        enabled: true,
        decal: {
          show: true,
        },
      },
      animation: false,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          // Since the data is reversed for display, we need to reverse the index back
          const pr = data[data.length - 1 - params[0].dataIndex]
          if (!pr) return ''

          const rows = [`PR #${pr.prNumber} - ${pr.title}`]

          // Group durations by stage and sum up all periods
          const stageDurations = pr.periods.reduce((acc, period) => {
            acc[period.stage] = (acc[period.stage] || 0) + period.duration
            return acc
          }, {} as Record<PRStage, number>)

          // Add each stage's total duration, but only if it has a non-zero duration
          Object.entries(stageDurations)
            .filter(([_, duration]) => duration > 0)
            .sort(([stageA], [stageB]) => {
              // Sort stages by their enum order
              return Object.values(PRStage).indexOf(stageA as PRStage) -
                     Object.values(PRStage).indexOf(stageB as PRStage)
            })
            .forEach(([stage, duration]) => {
              const hours = Math.round(duration)
              rows.push(`${stage}: ${formatDuration(hours)}`)
            })

          rows.push(`Total time: ${formatDuration(pr.totalTime)}`)

          return rows.join('<br/>')
        }
      },
      legend: {
        show: true,
        type: 'plain',
        top: 0,
        data: Object.values(PRStage),
        selectedMode: true
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: data.map(d => `#${d.prNumber}`).reverse()
      },
      series: series.map(s => ({
        ...s,
        data: s.data.reverse()
      }))
    }

    return {
      chartData: data,
      options: echartsOption,
    }
  }, [filteredPRs, currentPage])

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
      // Scroll to top of the component
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  // Initialize chart
  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstance.current) {
      chartInstance.current.dispose()
      chartInstance.current = null
    }

    if (!isLoading && !error && totalFilteredPRs > 0 && chartRef.current) {
      // Use canvas renderer instead of SVG for better performance with large datasets
      chartInstance.current = echarts.init(chartRef.current, 'light', { renderer: 'canvas' })

      // Defer chart initialization to next tick to prevent blocking
      setTimeout(() => {
        if (chartInstance.current) {
          chartInstance.current.setOption({
            ...options,
            animation: false,
            progressive: 500, // Render series progressively
            progressiveThreshold: 1000,
          })

          chartInstance.current.on('click', (params) => {
            const pr = chartData[chartData.length - 1 - params.dataIndex]
            if (pr) {
              window.open(pr.url, '_blank')
            }
          })
        }
      }, 0)

      // Handle resize
      const resizeObserver = new ResizeObserver(() => {
        if (chartInstance.current) {
          chartInstance.current.resize()
        }
      })
      resizeObserver.observe(chartRef.current)

      // Cleanup
      return () => {
        resizeObserver.disconnect()
        if (chartInstance.current) {
          chartInstance.current.dispose()
          chartInstance.current = null
        }
      }
    }
    return undefined
  }, [isLoading, error, totalFilteredPRs, options, chartData])

  return (
    <div key={key}>
      <h1>PR Metrics - {displayName}</h1>
      <PRFilters
        repoOptions={repoOptions}
        authorOptions={authorOptions}
        associationCounts={associationCounts}
        selectedRepos={selectedRepos}
        selectedAuthors={selectedAuthors}
        selectedAssociations={selectedAssociations}
        includeBots={includeBots}
        prState={prState}
        sortOption={sortOption}
        setSelectedRepos={handleRepoChange}
        setSelectedAuthors={handleAuthorChange}
        setSelectedAssociations={handleAssociationChange}
        setPrState={handlePrStateChange}
        handleIncludeBotsChange={handleIncludeBotsChange}
        setSortOption={handleSortChange}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div>
          {isLoading ?
            <p style={{ margin: 0 }}>Loading data...</p>
          : error ?
            <p style={{ margin: 0, color: 'red' }}>{error}</p>
          : <p style={{ margin: 0 }}>
              {totalFilteredPRs > 0 ?
                `${totalFilteredPRs} pull request${totalFilteredPRs !== 1 ? 's' : ''} analysed.`
              : '0 pull requests matching the current filters.'}
            </p>
          }
        </div>
      </div>
      {!isLoading && !error && totalFilteredPRs > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <div
        style={{
          height: `${Math.min(ITEMS_PER_PAGE, chartData.length) * 30 + 100}px`,
          minHeight: '400px',
          maxHeight: '3000px',
          position: 'relative',
          width: '100%',
        }}
      >
        {(() => {
          if (isLoading) {
            return <div style={{ textAlign: 'center', padding: '20px' }}>Loading chart data...</div>;
          }
          if (error) {
            return <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>{error}</div>;
          }
          if (totalFilteredPRs <= 0) {
            return <div style={{ textAlign: 'center', padding: '20px' }}>No data to display.</div>;
          }

          return (
            <div
              ref={chartRef}
              style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                border: '1px solid #eee'
              }}
            />
          );
        })()}
      </div>
      {!isLoading && !error && totalFilteredPRs > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  )
}

// Helper function to get consistent colors for stages
function getStageColor(stage: PRStage): string {
  switch (stage) {
    case PRStage.DEV:
      return '#2ecc71' // Green
    case PRStage.REVIEW:
      return '#3498db' // Blue
    case PRStage.MERGE:
      return '#9b59b6' // Purple
    case PRStage.IDLE:
      return '#95a5a6' // Gray
    case PRStage.INACTIVE:
      return '#e74c3c' // Red
    default:
      return '#bdc3c7' // Light gray
  }
}

// Convert hours into human-readable time parts
function formatDuration(hours: number): string {
  const years = Math.floor(hours / (24 * 365))
  const months = Math.floor((hours % (24 * 365)) / (24 * 30))
  const weeks = Math.floor((hours % (24 * 30)) / (24 * 7))
  const days = Math.floor((hours % (24 * 7)) / 24)
  const remainingHours = hours % 24

  const parts = []
  if (years > 0) parts.push(`${years}y`)
  if (months > 0) parts.push(`${months}m`)
  if (weeks > 0) parts.push(`${weeks}w`)
  if (days > 0) parts.push(`${days}d`)
  if (remainingHours > 0) parts.push(`${remainingHours}h`)

  return parts.join(' ')
}

export default OrgMetrics
