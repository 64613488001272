import React from 'react'
import ReactDOM from 'react-dom/client'
import DemoApp from './DemoApp'
import ErrorBoundary from '../ErrorBoundary'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <DemoApp />
    </ErrorBoundary>
  </React.StrictMode>,
)
